// Sidebar.js
import React from 'react';
import { FaUser, FaBell, FaComments, FaHome, FaCalendar, FaCogs } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <aside className="w-64 bg-white shadow-md hidden md:block">
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-6">Dashboard</h2>
        <nav className="space-y-4">
          <Link to="/dashboard" className="flex items-center text-gray-700 hover:bg-gray-200 p-3 rounded-md">
            <FaHome className="mr-3" /> Home
          </Link>
          <Link to="/profile" className="flex items-center text-gray-700 hover:bg-gray-700 p-3 rounded-md">
          <FaUser className="mr-3" /> Profile
         </Link>
         <Link to="#messages" className="flex items-center text-gray-200 hover:bg-gray-700 p-3 rounded-md">
          <FaComments className="mr-3" /> Messages
         </Link>
        <Link to="/notifications" className="flex items-center text-gray-200 hover:bg-gray-700 p-3 rounded-md">
          <FaBell className="mr-3" /> Notifications
        </Link>
        <Link to="#calendar" className="flex items-center text-gray-200 hover:bg-gray-700 p-3 rounded-md">
          <FaCalendar className="mr-3" /> Calendar
        </Link>
        <Link to="#settings" className="flex items-center text-gray-200 hover:bg-gray-700 p-3 rounded-md">
          <FaCogs className="mr-3" /> Settings
        </Link>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
