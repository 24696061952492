import { Link, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { get } from '../Helpers/Axios'; // Assuming `get` is a helper function for API calls
import Layout from '../Layout/Layout';
import { Helmet } from 'react-helmet';
import { FaThumbsUp, FaCommentDots, FaShareAlt } from 'react-icons/fa';
import logo from "../Images/logo1.jpg";
import publisherLogo from "../Images/publisher.png"; // Assuming there's a publisher image
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';

const ArticleDetails = () => {
    const { articleId } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [language, setLanguage] = useState('hi'); // State for language

    useEffect(() => {
        const fetchData = async () => {
            try {
                let Id = encodeURIComponent(articleId);
                setLoading(true);
                const response = await get(`/api/article/${Id}?lang=${language}`);
                setData(response.data); // Adjust based on the structure of your API response
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [articleId, language]);

    if (loading) return <div className="text-center text-gray-500">Loading...</div>;
    if (error) return <div className="text-center text-red-500">Error: {error.message}</div>;
    if (!data) return <div className="text-center text-gray-500">No data available</div>;

    const { title, content, author, created_at, imageUrl,user } = data;
    const articleUrl = window.location.href; // Gets the current URL
    console.log({data})
    return (
        <Layout>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={title} /> {/* Shortened content as meta description */}
            </Helmet>
            <div className="max-w-4xl mx-auto p-2 sm:p-4 my-6 bg-white rounded-lg shadow-md overflow-hidden">
                {/* Language Switch */}
                <div className="flex justify-between items-center w-full ">
                    <div className="flex items-center">
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                className="sr-only"
                                checked={language === 'hi'}
                                onChange={() => setLanguage(language === 'en' ? 'hi' : 'en')}
                            />
                            <div className="w-10 h-6 bg-gray-300 rounded-full shadow-inner relative">
                                <div
                                    className="dot absolute w-6 h-6 bg-blue-500 rounded-full shadow -left-1  transition-transform duration-300"
                                    style={{ transform: language === 'hi' ? 'translateX(100%)' : 'translateX(0)' }}
                                ></div>
                            </div>
                        </label>
                        <div className="mt-2 text-sm text-gray-600 ml-3">
                            {language === 'en' ? 'English' : 'हिन्दी'}
                        </div>
                    </div>
                    <div>
                        <img
                            src={logo}
                            alt="Logo"
                            className="w-16 h-16 object-cover rounded-lg transition-transform transform hover:scale-105"
                        />
                    </div>
                </div>

                <div>
                    {imageUrl && (
                        <div className="mb-6 flex justify-center">
                            <img
                                src={imageUrl}
                                alt={title}
                                className="w-full h-32 object-cover rounded-lg transition-transform transform hover:scale-105"
                            />
                        </div>
                    )}
                </div>
                <div className="sm:p-6">
                    <div className="flex w-full">
                        <div className="flex-shrink-0">
                            <img
                                src={user.profile_image_url?user.profile_image_url:publisherLogo}
                                alt="Publisher Logo"
                                className="hidden w-16 h-16 object-cover rounded-lg mr-4 mb-4 float-left"
                            />
                        </div>
                        <div className='w-full'>
                            <h1 className="text-md sm:text-2xl w-full py-4 font-extrabold text-gradient bg-gradient-to-r from-teal-400 to-blue-500 text-slate-900 bg-clip-text mb-4 rounded-lg text-center">
                                {title}
                            </h1>

                            <p className="text-gray-600 mb-4">
                                <span className="font-semibold text-sm sm:text-md">By:</span> {user.name?user.name:""} <span className="mx-2">|</span>
                                <span className="font-semibold text-sm sm:text-md">Published:</span> {new Date(created_at).toLocaleDateString()}
                            </p>
                        </div>
                    </div>
                    <p className="text-sm text-gray-800 mb-6">
                        {content}
                    </p>
                    <div className="flex justify-between items-center mt-6">
                        <div className="flex space-x-4">
                            <a href="#" className="flex items-center text-blue-500 hover:text-blue-600 font-semibold transition-transform transform hover:scale-110">
                                <FaThumbsUp className="mr-2" />
                                <span>Like</span>
                            </a>
                            <a href="#" className="flex items-center text-blue-500 hover:text-blue-600 font-semibold transition-transform transform hover:scale-110">
                                <FaCommentDots className="mr-2" />
                                <span>Comment</span>
                            </a>
                            <div className="flex items-center space-x-4 hidden">
                                <FacebookShareButton url={articleUrl} className="text-blue-600 hover:text-blue-700">
                                    <FaShareAlt className="mr-2" />
                                    <span>Share</span>
                                </FacebookShareButton>
                                <TwitterShareButton url={articleUrl} className="text-blue-400 hover:text-blue-500">
                                    <FaShareAlt className="mr-2" />
                                    <span>Tweet</span>
                                </TwitterShareButton>
                                <WhatsappShareButton url={articleUrl} className="text-green-500 hover:text-green-600">
                                    <FaShareAlt className="mr-2" />
                                    <span>Share</span>
                                </WhatsappShareButton>
                                <EmailShareButton url={articleUrl} className="text-gray-600 hover:text-gray-700">
                                    <FaShareAlt className="mr-2" />
                                    <span>Email</span>
                                </EmailShareButton>
                            </div>
                        </div>
                        <Link
                            to="/"
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition-transform transform hover:scale-105"
                        >
                            Go Back
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ArticleDetails;
