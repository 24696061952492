import React from 'react';
import { FaComments, FaBell, FaPlus, FaHome, FaUser } from 'react-icons/fa'; // Import necessary icons
import { useAuth } from '../Context/AuthContext'; // Adjust the path as necessary
import { Link } from 'react-router-dom';

const Footer = () => {
  const { state } = useAuth(); // Get authentication state from context
  const { isAuthenticated } = state; // Extract isAuthenticated from state

  return (
    <footer className="sm:bg-gray-700 text-white p-4 flex flex-col md:flex-row justify-between items-center">
      {/* Desktop Footer */}
      <div className="space-x-4 mb-4 md:mb-0 hidden md:flex">
        <a href="#" className="hover:underline">Curio |</a>
        <a href="#" className="hover:underline">Privacy Policy</a>
        <a href="#" className="hover:underline">Terms of Service</a>
        <a href="#" className="hover:underline">Sitemap</a>
      </div>
      <div className="space-x-4 mb-4 md:mb-0 hidden md:flex">
        <a href="#" className="hover:underline">Facebook</a>
        <a href="#" className="hover:underline">Twitter</a>
        <a href="#" className="hover:underline">LinkedIn</a>
        <a href="#" className="hover:underline">Instagram</a>
      </div>
      <div className="hidden ">
        <div>Address: 123 Street, Sagar, India</div>
      </div>

      {/* Mobile Footer */}
      {isAuthenticated && (
        <div className="fixed bottom-0 inset-x-0 bg-gray-900 text-white p-2 md:hidden flex justify-around items-center shadow-lg border-t border-gray-700">
          <Link to="/dashboard" className="flex flex-col items-center text-center hover:bg-gray-800 p-2 rounded-lg transition-colors duration-300">
            <FaHome className="text-md mb-1" />
            <span className="text-xs font-medium">Home</span>
          </Link>
          <Link to="/notifications" className="flex flex-col items-center text-center hover:bg-gray-800 p-2 rounded-lg transition-colors duration-300">
            <FaBell className="text-md mb-1" />
            <span className="text-xs font-medium">Notifications</span>
          </Link>
          <Link to="/profile" className="flex flex-col items-center text-center hover:bg-gray-800 p-2 rounded-lg transition-colors duration-300">
            <FaUser className="text-md mb-1" />
            <span className="text-xs font-medium">Profile</span>
          </Link>
        </div>
      )}
    </footer>
  );
};

export default Footer;
