import React from 'react';
import Header from './Header';
import Footer from './Footer'; // Uncomment if you have a Footer component

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        {children}
      </main>
       <Footer /> 
    </div>
  );
};

export default Layout;
