import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { truncateText } from '../Helpers/TruncateText';

const HeroSection = ({ sections, heroStyle, overlayStyle, textStyle }) => {
  console.log({ sections }); // For debugging purposes

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <section className={`relative ${heroStyle}`}>
      <div className={overlayStyle}></div>
      <div className="relative z-10 max-w-4xl mx-auto p-8 text-center">
        <Slider {...settings}>
          {sections && sections.length > 0 ? (
            sections.map((item) => {
              const truncatedContent = truncateText(item.content, 50); // Adjusted truncation length for more content

              return (
                <div key={item.id} className="p-4">
                  <h1 className={`mb-4 ${textStyle}`}>{item.title}</h1>
                  <p className="text-sm md:text-lg text-gray-700 mb-6">{truncatedContent}</p>
                  <div className="flex justify-center">
                    <Link
                      to={`/article/${encodeURIComponent(item.id)}`}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full shadow-lg transition-transform transform hover:scale-105"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-lg text-gray-200">No sections available</p>
          )}
        </Slider>
      </div>
    </section>
  );
};

export default HeroSection;
