import React, { useState } from 'react';
import Layout from "../../Layout/Layout";

const ChatUI = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: 'user' }]);
      setInput('');
    }
  };

  return (
    <Layout>
      <div className="flex  bg-gray-10">
        {/* Sidebar for Online Members */}
        <aside className="w-94 bg-indigo-600 text-white  flex-shrink-0">
          <div className="p-6 border-b border-indigo-500">
            <h2 className="text-2xl font-semibold">Online Members</h2>
          </div>
          <ul className="p-6 space-y-4">
            {/* Example online members */}
            {['John Doe', 'Jane Smith', 'Alice Johnson'].map((member, index) => (
              <li key={index} className="flex items-center p-3 hover:bg-purple-700 rounded-lg cursor-pointer transition-colors">
                <div className="w-12 h-12 bg-indigo-500 rounded-full flex items-center justify-center text-white text-xl font-semibold mr-4">
                  {member[0]}
                </div>
                <span className="text-lg">{member}</span>
              </li>
            ))}
          </ul>
        </aside>

        {/* Chat Area */}
        <div className="flex-1 flex flex-col">
          <header className="p-6 text-white text-3xl font-bold">
            Chat Room
          </header>
          <div className="flex-1 overflow-y-auto p-6">
            <div className="space-y-4">
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                >
                  <div
                    className={`p-4 rounded-xl max-w-sm shadow-lg ${msg.sender === 'user' ? 'bg-teal-600 text-white' : 'bg-white text-gray-900 border border-gray-300'}`}
                  >
                    {msg.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="p-4 border-t border-gray-300 flex items-center bg-white shadow-md">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="flex-1 p-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-teal-500 transition-all"
              placeholder="Type your message..."
            />
            <button
              onClick={handleSend}
              className="ml-4 bg-teal-600 text-white p-4 rounded-full hover:bg-teal-700 transition-all focus:outline-none focus:ring-2 focus:ring-teal-500"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChatUI;
