import React, { useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import HeroSection from '../Components/HeroSection';
import { get } from '../Helpers/Axios';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loader from '../Components/Loader'; // Adjust the import path if needed
import ShareMarketHeader from '../Components/ShareMarketHeader';

const HomePage = () => {
  const [data, setData] = useState(null); // Use null to differentiate between loaded and not loaded
  const [loading, setLoading] = useState(true); // New state for loading

  const fetchData = async () => { 
    try {
      const response = await get('/api/article-sections');
      return response.data;
    } catch (error) {
      console.error('Error fetching article sections:', error);
      throw error; // Re-throw the error for handling in the calling component
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchData();
        setData(fetchedData);
      } catch (error) {
        // Optionally handle the error here
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    getData();
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Curio</title>
        <meta name="description" content="Discover the wonders that pique your curiosity! Curio News brings you stories about rare finds, historical oddities, and captivating collectibles." />
      </Helmet>

      {loading ? (
        <Loader /> // Show the loader while loading
      ) : (
        <>
          {/* Sticky Header */}
          <ShareMarketHeader />

          <div className="pt-0 font-sans"> {/* Add padding to avoid content overlapping with header */}
            {/* Hero Section */}
            <HeroSection
              sections={data.main_hr_section}
              heroStyle="bg-cover bg-center h-96 relative"
              overlayStyle="absolute inset-0 bg-yellow-100 bg-opacity-50"
              textStyle="relative z-10  text-gray-900 text-xl font-bold"
            />

            {/* Latest News Section */}
            <section className="bg-blue-100 p-8">
              <h2 className="text-2xl font-bold mb-4">Latest News (ताज़ा ख़बरें)</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* News Articles Grid */}
                {data.latest_news && data.latest_news.map((news) => (
                  <div key={news.id} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                    <h3 className="text-lg font-semibold mb-2">
                      <Link
                        to={`/article/${encodeURIComponent(news.id)}`}
                        className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
                      >
                        {news.title}
                      </Link>
                    </h3>
                    <p className="text-gray-600">{news.excerpt}</p>
                    <Link to={`/article/${encodeURIComponent(news.id)}`} className="text-blue-600 hover:text-blue-800 mt-2 block">
                      Read More
                    </Link>
                  </div>
                ))}
              </div>
            </section>

            {/* Categories Section */}
            <section className="bg-green-100 p-8">
              <h2 className="text-2xl font-bold mb-4">Browse by Category</h2>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                {/* Category Tiles */}
                {data.first_four_categories.map((cat) => (
                  <div key={cat.id} className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-lg font-semibold">{cat.hindi_name}</h4>
                  </div>
                ))}
              </div>
            </section>

            {/* Featured Health Tips */}
            <section className="bg-red-100 p-8">
              <h2 className="text-2xl font-bold mb-4">Health Tips</h2>
              <div className="overflow-x-scroll flex space-x-4">
                {/* Tips Slider */}
                <div className="bg-white p-4 rounded-lg shadow-lg min-w-[200px]">Nutrition & Diet</div>
                <div className="bg-white p-4 rounded-lg shadow-lg min-w-[200px]">Fitness & Exercise</div>
                <div className="bg-white p-4 rounded-lg shadow-lg min-w-[200px]">Mental Health & Well-being</div>
                <div className="bg-white p-4 rounded-lg shadow-lg min-w-[200px]">Stress Management</div>
              </div>
            </section>

            {/* Expert Opinions */}
            <section className="bg-pink-100 p-8 hidden">
              <h2 className="text-2xl font-bold mb-4">Expert Opinions</h2>
              <div className="space-y-4">
                {/* Articles List */}
                <div className="bg-white p-4 rounded-lg shadow-lg">Opinion 1</div>
                <div className="bg-white p-4 rounded-lg shadow-lg">Opinion 2</div>
                <div className="bg-white p-4 rounded-lg shadow-lg">Opinion 3</div>
              </div>
            </section>
          </div>
        </>
      )}
    </Layout>
  );
};

export default HomePage;
