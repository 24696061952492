import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline'; // Icons from Heroicons v2

const NotificationCard = ({ type, message, onClose }) => {
  const getTypeStyles = (type) => {
    switch (type) {
      case 'success':
        return {
          bgColor: 'bg-green-100',
          borderColor: 'border-green-500',
          textColor: 'text-green-800',
          icon: <CheckCircleIcon className="h-6 w-6 text-green-800" />,
        };
      case 'error':
        return {
          bgColor: 'bg-red-100',
          borderColor: 'border-red-500',
          textColor: 'text-red-800',
          icon: <ExclamationCircleIcon className="h-6 w-6 text-red-800" />,
        };
      case 'info':
        return {
          bgColor: 'bg-blue-100',
          borderColor: 'border-blue-500',
          textColor: 'text-blue-800',
          icon: <InformationCircleIcon className="h-6 w-6 text-blue-800" />,
        };
      default:
        return {
          bgColor: 'bg-gray-100',
          borderColor: 'border-gray-500',
          textColor: 'text-gray-800',
          icon: <InformationCircleIcon className="h-6 w-6 text-gray-800" />,
        };
    }
  };

  const { bgColor, borderColor, textColor, icon } = getTypeStyles(type);

  return (
    <div className={`flex items-center p-4 mb-4 border ${borderColor} rounded-lg shadow-md ${bgColor}`}>
      {icon}
      <div className="flex-1 ml-4">
        <p className={`text-lg font-semibold ${textColor}`}>{message}</p>
      </div>
      <button
        onClick={onClose}
        className="text-gray-500 hover:text-gray-700 focus:outline-none"
        aria-label="Close notification"
      >
        <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </div>
  );
};

export default NotificationCard;
