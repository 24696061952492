import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { get } from '../Helpers/Axios';
import { truncateText } from '../Helpers/TruncateText';
import Loader from '../Components/Loader';

const articles = [
  { id: '1', title: 'The Future of Healthcare', summary: 'Exploring upcoming trends and innovations in healthcare.', date: '2024-07-20' },
  { id: '2', title: 'Advancements in AI Technology', summary: 'How artificial intelligence is shaping the tech landscape.', date: '2024-07-21' },
  { id: '3', title: 'Sustainable Living Practices', summary: 'Tips and insights for a more eco-friendly lifestyle.', date: '2024-07-22' },
  // Add more articles as needed
];

function ArticleListingPage() {  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await get('/api/latest-articles'); // Adjust according to your HTTP client
                setData(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        // Optional cleanup function if needed
        return () => {
            // Example cleanup: aborting fetch requests if using a library like axios
            // source.cancel('Operation canceled by the user.');
        };
    }, []);

    return (
        <Layout>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-xl rounded-lg font-extrabold text-center mb-12 text-gradient bg-clip-text text-slate-900 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 py-2">
                    Your Gateway to Knowledge: Latest News
                </h1>
                {loading ? (
                    <Loader /> // Show loader while data is loading
                ) : error ? (
                    <p className="text-red-500 text-center">Failed to load articles: {error}</p> // Handle error
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {data.map((article, index) => (
                            <div key={article.id} className="bg-white shadow-md rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300">
                                <div className="relative">
                                    <div className="absolute top-0 left-0 p-4 bg-gradient-to-r from-teal-500 to-teal-400 text-white rounded-br-lg">
                                        <p className="text-xs uppercase font-semibold">Featured</p>
                                    </div>
                                    <img
                                        src={`https://via.placeholder.com/400x250?text=${article.title}`}
                                        alt={article.title}
                                        className="w-full h-40 object-cover"
                                    />
                                </div>
                                <div className="p-6">
                                    <h2 className="text-lg font-bold mb-3 text-gray-800 hover:text-blue-600 transition-colors duration-300">{article.title}</h2>
                                    <p className="text-gray-600 mb-4 text-md">{truncateText(article.content, 20)}</p>
                                    <p className="text-gray-500 text-sm mb-6 hidden">{new Date(article.created_at).toLocaleDateString()}</p>
                                    <Link
                                        to={`/article/${encodeURIComponent(article.id)}`}
                                        className="inline-block px-5 py-3 bg-blue-600 text-white font-semibold rounded-full shadow-md hover:bg-blue-700 transition-colors duration-300"
                                    >
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default ArticleListingPage;
