import React, { useState, useEffect, useReducer } from 'react';
import { FaThumbsUp, FaShareAlt, FaComment } from 'react-icons/fa';
import Layout from '../../Layout/Layout';
import { get, post } from '../../Helpers/Axios';
import { useAuth } from '../../Context/AuthContext';
import { truncateText } from '../../Helpers/TruncateText';
import { postReducer, initialState } from '../../Reducers/postReducer';
import Sidebar from '../../Layout/Sidebar';


const getEmbedUrl = (url) => {
  // Handle YouTube URLs
  if (url.includes('youtube.com/watch?v=')) {
    return url.replace('youtube.com/watch?v=', 'youtube.com/embed/');
  } else if (url.includes('youtube.com/shorts/')) {
    return url.replace('youtube.com/shorts/', 'youtube.com/embed/').split('?')[0];
  } else if (url.includes('youtu.be/')) {
    return url.replace('youtu.be/', 'youtube.com/embed/');
  }
  
  // Handle Vimeo URLs
  const vimeoMatch = url.match(/vimeo\.com\/(\d+)/);
  if (vimeoMatch) {
    return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
  }
  
  // Add more handlers if needed for other platforms

  // Default case, return the original URL for unknown platforms
  return url;
};


const Dashboard = () => {   
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [expandedPosts, setExpandedPosts] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [commentBox, setCommentBox] = useState({});
  const [postState, dispatch] = useReducer(postReducer, initialState);

  const { state } = useAuth();
  const { isAuthenticated, user, token } = state;

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await get('/api/posts', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const handlePostChange = (e) => setNewPost(e.target.value);

  const handlePostSubmit = async () => {
    if (newPost.trim()) {
      try {
        setIsLoading(true);
        const response = await post('/api/posts', {
          content: newPost,
          media_url: '',
          status: 'published',
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setIsLoading(false);
        setPosts([response.data, ...posts]);
        setNewPost('');
        fetchPosts();
      } catch (error) {
        setIsLoading(false);
        console.error('Error creating post:', error.response || error.message || error);
        alert(`Error: ${error.response?.data?.message || error.message || 'An unexpected error occurred'}`);
      }
    }
  };

  const likePost = async (postId) => {
    try {
      await post(`/api/posts/${postId}/like`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      dispatch({ type: 'SET_POST_ID', payload: postId });

      fetchPosts();
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const toggleExpandPost = (postId) => {
    setExpandedPosts(prevState => ({
      ...prevState,
      [postId]: !prevState[postId]
    }));
  };

  const handleCommentClick = (postId) => {
    setCommentBox(prevState => ({
      ...prevState,
      [postId]: !prevState[postId]
    }));
  };

  const handleCommentSubmit = async (postId, comment) => {
    if (comment.trim()) {
      try {
        await post(`/api/comments`, {
          content: comment,
          commentable_id: postId,
          commentable_type: "App\\Models\\Post",
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        fetchPosts();
        setCommentBox(prevState => ({
          ...prevState,
          [postId]: false
        }));
      } catch (error) {
        console.error('Error submitting comment:', error);
        alert(`Error: ${error.response?.data?.message || error.message || 'An unexpected error occurred'}`);
      }
    }
  };

  const getInitials = (firstName, lastName, name) => {
    if (!firstName && !lastName) {
      return name ? name.charAt(0).toUpperCase() : '?';
    }
    if (!firstName) {
      return lastName.charAt(0).toUpperCase();
    }
    if (!lastName) {
      return firstName.charAt(0).toUpperCase();
    }
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  };

  const renderMediaContent = (media_url) => {
    if (!media_url) return null;
    const isVideo = /(?:youtube\.com\/(?:.*\bwatch\?v=|.*\bshorts\/|.*\be(?:mbed)?\/|.*[?&]v=)|youtu\.be\/|vimeo\.com\/\d+)/i.test(media_url);
  
    return (
      <div className="mt-4">
        {isVideo ? (
          <iframe
            width="100%"
            height="400"
            src={getEmbedUrl(media_url)}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="rounded-lg shadow-md"
          ></iframe>
        ) : (
          <img
            src={media_url}
            alt="Post Media"
            className="w-full max-h-96 object-cover rounded-lg shadow-md"
          />
        )}
      </div>
    );
  };

  return ( 
    <Layout>
      <div className="flex">
        <Sidebar /> {/* Use the Sidebar component */}

        <main className="flex-1 p-2 sm:p-8 flex flex-col">
          <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <h3 className="text-xl font-semibold mb-4">Create a Post</h3>
            <textarea
              className="w-full p-3 border border-gray-300 rounded-lg mb-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="4"
              placeholder="What's on your mind?"
              value={newPost}
              onChange={handlePostChange}
            />
            <button
              className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300"
              onClick={handlePostSubmit}
            >
              {isLoading ? "Please wait ..." : "Post"}
            </button>
          </div>

          <div className="bg-white p-2 sm:p-6 rounded-lg shadow-sm flex-1 h-auto">
            <h3 className="text-xl font-semibold mb-4">Feed</h3>
            <div className="space-y-6">
              {posts.map(post => {
                const truncatedContent = truncateText(post.content);
                const isExpanded = expandedPosts[post.id] || false;
                const showCommentBox = commentBox[post.id] || false;

                return (
                  <div key={post.id} className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 hover:shadow-xl transition-shadow duration-300">
                    <div className="flex items-center mb-4">
                      <div className={`text-white bg-indigo-900 rounded-full flex items-center justify-center w-12 h-12 text-xl font-bold mr-4 ${post.bgColor}`}>
                        {post && getInitials(post.postable?.first_name, post.postable?.last_name, post.postable?.name)}
                      </div>
                      <div>
                        <h4 className="text-lg font-semibold">{post.postable?.first_name} {post.postable?.last_name} {post.postable?.name}</h4>
                        <p className="text-gray-500 text-sm">{post.time}</p>
                      </div>
                    </div>
                    <p className="text-gray-700 mb-4">
                      {isExpanded ? post.content : truncatedContent}
                      {!isExpanded && (
                        <button
                          onClick={() => toggleExpandPost(post.id)}
                          className="text-blue-600 hover:text-blue-800 ml-2"
                        >
                          {isExpanded ? 'Show Less' : 'Read More'}
                        </button>
                      )}
                    </p>
                    
                    {isExpanded && renderMediaContent(post.media_url)}
                    
                    <div className="flex items-center justify-start mb-4 space-x-2">
                      <button
                        className="flex items-center text-gray-600 hover:text-gray-800"
                        onClick={() => likePost(post.id)}
                      >
                        <FaThumbsUp className="mr-2" /> {post.likes_count}
                      </button>
                      <button
                        className="flex items-center text-gray-600 hover:text-gray-800"
                        onClick={() => handleCommentClick(post.id)}
                      >
                        <FaComment className="mr-2" /> {post.comments_count}
                      </button>
                    </div>
                    {showCommentBox && (
                      <div className="bg-gray-100 p-4 rounded-lg border border-gray-200 mt-4">
                        <textarea
                          className="w-full p-3 border border-gray-300 rounded-lg mb-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          rows="3"
                          placeholder="Write a comment..."
                          onBlur={(e) => handleCommentSubmit(post.id, e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default Dashboard;
