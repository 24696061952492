import React from 'react';

const SvgContainerStyle = {
  width: '200px',
  height: '50px',
};

const ApplicationLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={SvgContainerStyle}>
    <defs>
      <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "rgb(0,0,255)", stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <text x="10" y="40" fontFamily="Pacifico" fontSize="45" fill="url(#gradient1)">Curio</text>
  </svg>
);

export default ApplicationLogo;
