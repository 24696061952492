// Profile.js
import React from 'react';
import { FaUser, FaEnvelope, FaPhone, FaLocationArrow } from 'react-icons/fa';
import Layout from '../../Layout/Layout';
import Sidebar from '../../Layout/Sidebar';
import { useAuth } from '../../Context/AuthContext';
import { getUserInitials } from '../../Helpers/TruncateText';

const Profile = () => {  
    
  const { state } = useAuth();
  const { isAuthenticated, user, token } = state;
  return (
    <Layout>
      <div className="flex">
        <Sidebar /> {/* Use the Sidebar component */}

        <main className="flex-1  min-h-screen">
          <div className="bg-white shadow-md rounded-lg overflow-hidden mx-4 my-6 max-w-4xl">
            {/* Profile Header */}
            <div className="bg-gradient-to-r from-indigo-600 to-purple-600 p-6 text-white">
              <div className="flex items-center">
                 
                  <p className="w-24 h-24 rounded-full border-2 border-white p-8"> {getUserInitials(user.name)}</p>

              
                <div className="ml-6">
                  <h1 className="text-3xl font-semibold">{user.name}</h1>
                  <p className="text-lg mt-1">Unkwon | </p>
                </div>
              </div>
            </div>

            {/* Profile Details */}
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Basic Information */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <h2 className="text-xl font-semibold mb-4">Basic Information</h2>
                  <div className="flex items-center mb-4">
                    <FaUser className="text-indigo-500 mr-3" />
                    <span className="text-gray-700">Name: {user.name}</span>
                  </div>
                  <div className="flex items-center mb-4">
                    <FaEnvelope className="text-indigo-500 mr-3" />
                    <span className="text-gray-700">Email:{user.email}</span>
                  </div>
                  <div className="flex items-center mb-4">
                    <FaPhone className="text-indigo-500 mr-3" />
                    <span className="text-gray-700">Phone:{user.phone}</span>
                  </div>
                  <div className="flex items-center mb-4">
                    <FaLocationArrow className="text-indigo-500 mr-3" />
                    <span className="text-gray-700">Location:India</span>
                  </div>
                </div>

                {/* About Me Section */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <h2 className="text-xl font-semibold mb-4">About Me</h2>
                  <p className="text-gray-700">
                       </p>
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className="bg-gray-200 p-4 text-center">
              <p className="text-gray-600">&copy; 2024 {user.name}. All rights reserved.</p>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default Profile;
