import React, { useState, useEffect } from 'react';
import Layout from '../Layout/Layout';
import Sidebar from '../Layout/Sidebar';
import NotificationCard from '../Components/NotificationCard';

const NotificationPage = () => {
  const [activeTab, setActiveTab] = useState('current');
  const [currentNotifications, setCurrentNotifications] = useState([
    {
      id: 1,
      type: 'info',
      message: 'Instamedic Mind Spark: Revolutionizing mental wellness through innovative programs.',
      visible: true,
    },
    {
      id: 2,
      type: 'success',
      message: 'Transform 360 Programs: Empowering your health journey with comprehensive wellness solutions.',
      visible: true,
    },
  ]);
  const [pastNotifications, setPastNotifications] = useState([
    {
      id: 3,
      type: 'error',
      message: 'System update completed with some issues.',
      visible: false,
    },
    {
      id: 4,
      type: 'info',
      message: 'Scheduled maintenance window for next week.',
      visible: false,
    },
  ]);

  useEffect(() => {
    const timers = currentNotifications.map(notification => {
      return setTimeout(() => {
        setCurrentNotifications(prev =>
          prev.map(n =>
            n.id === notification.id ? { ...n, visible: false } : n
          )
        );
      }, 5000); // Hide after 5 seconds
    });

    return () => {
      timers.forEach(timer => clearTimeout(timer));
    };
  }, [currentNotifications]);

  return (
    <Layout>
      <div className="flex">
        <Sidebar className="w-64 bg-gray-800 text-white" /> {/* Sidebar on the left */}
        <div className="flex-1 p-6">
          <div className="bg-white shadow rounded-lg">
            <div className="border-b border-gray-200">
              <nav className="flex">
                <button
                  className={`py-2 px-4 text-sm font-medium ${
                    activeTab === 'current' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'
                  }`}
                  onClick={() => setActiveTab('current')}
                >
                  Current Notifications
                </button>
                <button
                  className={`py-2 px-4 text-sm font-medium ${
                    activeTab === 'past' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'
                  }`}
                  onClick={() => setActiveTab('past')}
                >
                  Past Notifications
                </button>
              </nav>
            </div>
            <div className="p-4">
              {activeTab === 'current' && (
                <div>
                  {currentNotifications.map(notification => (
                    notification.visible && (
                      <NotificationCard
                        key={notification.id}
                        type={notification.type}
                        message={notification.message}
                        onClose={() => {
                          setCurrentNotifications(prev =>
                            prev.filter(n => n.id !== notification.id)
                          );
                        }}
                      />
                    )
                  ))}
                </div>
              )}
              {activeTab === 'past' && (
                <div>
                  {pastNotifications.map(notification => (
                    notification.visible && (
                      <NotificationCard
                        key={notification.id}
                        type={notification.type}
                        message={notification.message}
                        onClose={() => {
                          setPastNotifications(prev =>
                            prev.filter(n => n.id !== notification.id)
                          );
                        }}
                      />
                    )
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotificationPage;
