import React, { useEffect, useReducer, useState } from 'react';
import { FaBars, FaTimes, FaSearch, FaUser, FaBell, FaComments, FaChartLine } from 'react-icons/fa';
import ApplicationLogo from '../Components/ApplicationLogo';
import { useAuth } from '../Context/AuthContext';
import { Link } from 'react-router-dom';
import Pusher from 'pusher-js';
import { postReducer,initialState } from '../Reducers/postReducer';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const { state } = useAuth(); // Get authentication state from context
  const { isAuthenticated, user } = state; // Extract isAuthenticated and user from state
  const [postState, dispatch] = useReducer(postReducer, initialState);
  

  useEffect(() => {
   // console.log({postState})
    //const {postId}=postState;
 const  postId=64; //postState.postId;
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = true;

    var pusher = new Pusher('750fab8ca1f4d768dabc', {
      cluster: 'ap2'
    });
    const channel = pusher.subscribe(`post.${postId}`);
    channel.bind('post-action-event', function(data) {
      setNotifications(prev => [
        ...prev,
        `${data.user} ${data.action}${data.comment ? `: "${data.comment}"` : ''}`,
      ]);
    });

    return () => {
      pusher.unsubscribe(`post.${postId}`);
    };
  },[])

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to get user initials
  const getUserInitials = (name) => {
    if (!name) return '';
    const names = name.split(' ');
    const initials = names.length > 1
      ? names[0][0] + names[1][0]
      : names[0][0];
    return initials.toUpperCase();
  };

  return (
    <header className="bg-white p-4 flex justify-between items-center shadow-md z-50">
      <div className="flex items-center">
        <ApplicationLogo />
      </div>
      <nav className="hidden md:flex space-x-4 sm:space-x-8">
        {isAuthenticated ? (
          <>
            <Link to="/profile" className="flex flex-col items-center text-center hover:text-blue-300">
              <FaUser className="text-2xl mb-1" />
              <span className="text-sm">Profile</span>
            </Link>
            <Link to="/notifications" className="flex flex-col items-center text-center hover:text-blue-300">
              <FaBell className="text-2xl mb-1" />
              <span className="text-sm">Notifications</span>
            </Link>
            <Link to="#chat" className="flex flex-col items-center text-center hover:text-blue-300">
              <FaComments className="text-2xl mb-1" />
              <span className="text-sm">Messages</span>
            </Link>
            <a href="#analytics" className="flex flex-col items-center text-center hover:text-blue-300">
              <FaChartLine className="text-2xl mb-1" />
              <span className="text-sm">Analytics</span>
            </a>
          </>
        ) : (
          <>
            <a href="/" className="hover:underline font-semibold text-indigo-900">Home</a>
            <a href="/articles" className="hover:underline font-semibold text-indigo-900">News</a>
            <a href="/" className="hover:underline font-semibold text-indigo-900">Health Tips</a>
            <a href="/" className="hover:underline font-semibold text-indigo-900">Expert Opinions</a>
            <a href="/" className="hover:underline font-semibold text-indigo-900">Contact Us</a>
          </>
        )}
      </nav>
      <div className="hidden md:flex">
        <div className="flex items-center rounded p-1">
          {isAuthenticated ? (
            <div className="flex items-center">
              <div className="bg-indigo-900 text-white rounded-full flex items-center justify-center w-10 h-10 text-xl font-bold">
                {getUserInitials(user.name)} {/* Display user initials */}
              </div>
            </div>
          ) : (
            <button className="flex items-center justify-center hover:bg-gray-300 rounded p-1">
              <a href='/signin' className="hover:underline font-semibold text-indigo-900">Signin</a>
            </button>
          )}
        </div>
      </div>
      <div className="md:hidden flex items-center">
        <button onClick={toggleMenu}>
          {isOpen ? (
            <FaTimes className="h-6 w-6 text-black" />
          ) : (
            <FaBars className="h-6 w-6 text-black" />
          )}
        </button>
      </div>
      {isOpen && (
        <div className="md:hidden fixed inset-0 z-50 flex">
          <div className="absolute inset-0 bg-gray-800 opacity-75" onClick={toggleMenu}></div>
          <div className="absolute inset-y-0 left-0 w-64 bg-white p-4 shadow-md">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <ApplicationLogo />
              </div>
              <button onClick={toggleMenu}>
                <FaTimes className="h-6 w-6 text-black" />
              </button>
            </div>
            <nav className="space-y-4">
              {isAuthenticated ? (
                <>
                  <Link to="/profile" className="flex flex-col items-center text-center hover:underline font-semibold text-indigo-900">
                    <FaUser className="text-2xl mb-1" />
                    <span className="text-sm">Profile</span>
                  </Link>
                  <a href="#notifications" className="flex flex-col items-center text-center hover:underline font-semibold text-indigo-900">
                    <FaBell className="text-2xl mb-1" />
                    <span className="text-sm">Notifications</span>
                  </a>
                  <Link to="chat" className="flex flex-col items-center text-center hover:underline font-semibold text-indigo-900">
                    <FaComments className="text-2xl mb-1" />
                    <span className="text-sm">Messages</span>
                  </Link>
                  <a href="#analytics" className="flex flex-col items-center text-center hover:underline font-semibold text-indigo-900">
                    <FaChartLine className="text-2xl mb-1" />
                    <span className="text-sm">Analytics</span>
                  </a>
                </>
              ) : (
                <>
                  <a href="/" className="block text-lg hover:underline font-semibold text-indigo-900">Home</a>
                  <a href="/articles" className="block text-lg hover:underline font-semibold text-indigo-900">News</a>
                  <a href="/" className="block text-lg hover:underline font-semibold text-indigo-900">Health Tips</a>
                  <a href="/" className="block text-lg hover:underline font-semibold text-indigo-900">Expert Opinions</a>
                  <a href="/" className="block text-lg hover:underline font-semibold text-indigo-900">Contact Us</a>
                </>
              )}
            </nav>
            <div className="mt-4">
              <div className={`flex items-center ${isAuthenticated ? 'justify-center' : ' justify-start'}`}>
                {isAuthenticated ? (
                  <div className="flex items-center">
                    <div className="bg-indigo-900 text-white rounded-full flex items-center justify-center w-10 h-10 text-xl font-bold">
                      {getUserInitials(user.name)} {/* Display user initials */}
                    </div>
                  </div>
                ) : (
                  <button className="flex items-center justify-center">
                    <a href='/signin' className="hover:underline font-semibold text-indigo-900">Signin</a>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
