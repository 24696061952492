import React, { useState } from 'react';
import Layout from '../../Layout/Layout';
import { useAuth } from '../../Context/AuthContext';
import { Link, Navigate } from 'react-router-dom';

const SignIn = () => {
  const { login, state } = useAuth();
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });

  const [isEmail, setIsEmail] = useState(true);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
    if (id === 'identifier') {
      setIsEmail(value.includes('@'));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(formData);
      if (state.error) {
        throw new Error(state.error);
      }

      console.log('Login successful');
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  return (
    <Layout>
      <div className="flex flex-col md:flex-row min-h-screen bg-gradient-to-r from-blue-100 via-indigo-200 to-purple-300">
        {/* Left Section (Desktop Only) */}
        <div className="hidden md:flex md:w-1/2 bg-gradient-to-br from-blue-500 to-indigo-600 p-8 text-center items-center justify-center">
          <div className="text-white">
            <h1 className="text-4xl font-extrabold mb-4">
              Welcome to <span className="text-yellow-300">CurioX</span>
            </h1>
            <p className="text-lg">
              Join the CurioX community and experience a new world of health and wellness.
            </p>
          </div>
        </div>

        {/* Right Section (Sign-In Form) */}
        <div className="flex-1 flex sm:items-center justify-center p-6 md:p-12 bg-white shadow-lg">
          <div className="w-full max-w-md bg-white p-8 rounded-3xl shadow-2xl">
            <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">
              Sign In to CurioX
            </h2>
            {state.error && (
              <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-600 rounded-lg text-sm">
                {state.error}
              </div>
            )}

            {state.isAuthenticated && <Navigate to="/dashboard" replace={true} />}

            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label
                  htmlFor="identifier"
                  className="block text-gray-700 text-sm font-medium"
                >
                  {isEmail ? 'Email or Phone' : 'Phone Number'}
                </label>
                <input
                  type={isEmail ? 'email' : 'tel'}
                  id="identifier"
                  className="w-full p-4 mt-2 border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-4 focus:ring-indigo-500"
                  value={formData.identifier}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="password"
                  className="block text-gray-700 text-sm font-medium"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="w-full p-4 mt-2 border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-4 focus:ring-indigo-500"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 rounded-xl shadow-lg hover:from-indigo-600 hover:to-blue-500 focus:outline-none focus:ring-4 focus:ring-indigo-500 text-lg font-medium transition duration-200"
              >
                Sign In
              </button>
            </form>
            <p className="mt-6 text-center text-gray-600 text-sm">
              Don't have an account?{' '}
              <Link
                to="/signup"
                className="text-indigo-600 hover:text-indigo-800 font-semibold"
              >
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SignIn;
