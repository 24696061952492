import React, { useEffect, useState } from 'react';
import { get } from '../Helpers/Axios'; // Adjust the import path if needed
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Optional: Add your own loader component or use a library component
const Loader = () => (
  <div className="flex justify-center items-center h-full">
    <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
  </div>
);

const SYMBOL = 'AAPL'; // Change this to the stock symbol you want to fetch

const ShareMarketHeader = () => {
  const [marketNews, setMarketNews] = useState(false);
  const [loading, setLoading] = useState(true); // State for loading

  const fetchPrimaryMarketNews = async () => {
    try {
      const response = await get('https://www.alphavantage.co/query', {
        params: {
          function: 'NEWS_SENTIMENT',
          symbol: SYMBOL,
          apikey: 'BEHJJLKIDM0NW2X6'
        }
      });
      return response.data.feed; // Return data if successful
    } catch (error) {
      console.error('Error fetching from primary API:', error);
      throw error; // Rethrow error to trigger fallback
    }
  };

  const fetchFallbackMarketNews = async () => {
    try {
      const response = await get('https://api.worldnewsapi.com/top-news',  {
        params: {
          'source-country': 'us', // ISO 3166 country code for the USA
          language: 'en',         // ISO 6391 language code for English
          date: new Date().toISOString().split('T')[0], // Current date in YYYY-MM-DD format
          'headlines-only': 'false', // Optional: to get detailed news
          'api-key': '7417cf786e2e428c841a983c591a354b' // Replace with your actual API key
        }
      });

      const newsArray = response.data.top_news.flatMap(item => item.news || []);
      return newsArray;
    } catch (error) {
      console.error('Error fetching from fallback API:', error);
      throw error; // Rethrow error if both APIs fail
    }
  };

  const fetchMarketNews = async () => {
    setLoading(true);
    try {
      let data = await fetchPrimaryMarketNews();
      if (!data || data.length === 0) { // Check if data is empty or undefined
        data = await fetchFallbackMarketNews();
      }
      setMarketNews(data);
    } catch {
      try {
        const data = await fetchFallbackMarketNews();
        setMarketNews(data);
      } catch {
        setMarketNews([]); // Set to empty if both APIs fail
      }
    } finally {
      setLoading(false); // Set loading to false after attempting to fetch data
    }
  };

  useEffect(() => {
       if(!marketNews){
        fetchMarketNews(); // Fetch market news only on page refresh
       }else{
        console.log({marketNews})
       }
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false
  };

  return (
    <header className="w-full bg-white shadow-md">
      <div className="bg-gray-100 p-4 max-h-32">
        <div className="container mx-auto">
          <h2 className="text-xl font-bold mb-2">Trending News</h2>
          {loading ? (
            <Loader /> // Show the loader while loading
          ) : marketNews?.length > 0 ? (
            <Slider {...sliderSettings}>
              {marketNews.map((news, index) => (
                <div key={index} className="p-4 bg-blue-100 rounded-md shadow-lg">
                  <h3 className="text-sm font-semibold text-blue-800">{news.title}</h3>
                  <p className="text-gray-600 mt-2 hidden">{news.summary}</p>
                </div>
              ))}
            </Slider>
          ) : (
            <p className="text-gray-600">No market news available</p>
          )}
        </div>
      </div>
    </header>
  );
};

export default ShareMarketHeader;
