import React from 'react';
import { BrowserRouter, Routes, Route, Link, useParams } from 'react-router-dom';
import './App.css';
import HomePage from './Pages/HomePage';
import ArticleDetails from './Pages/ArticleDetails';
import ArticleListingPage from './Pages/ArticleListingPage';
import SignIn from './Pages/Auth/SignIn';
import SignUp from './Pages/Auth/SignUp';
import Dashboard from './Pages/Auth/Dashboard';
import { AuthProvider } from './Context/AuthContext';
import ProtectedRoute from './Routes/ProtectedRoute';
import ChatUI from './Pages/Auth/ChatUI';
import Profile from './Pages/Auth/Profile';
import Notification from './Pages/Notification';
import NotificationPage from './Pages/NotificationPage';

function App() {
  return (  
     <AuthProvider>
        <div className="App">
          <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/article/:articleId" element={<ArticleDetails />} /> {/* Add this route */}
          <Route path='/articles' element ={<ArticleListingPage />}  />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />

          <Route
              path="/dashboard"
              element={<ProtectedRoute element={<Dashboard />} />}
            />
            <Route
              path="/chat"
              element={<ProtectedRoute element={<ChatUI />} />}
            />
             <Route
              path="/profile"
              element={<ProtectedRoute element={<Profile />} />}
            />
            <Route
              path="/notifications"
              element={<ProtectedRoute element={<NotificationPage />} />}
            />
          
        </Routes>
         </BrowserRouter>
        </div>
    </AuthProvider>
  );
}

export default App;
